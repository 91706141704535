<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('offers_page.title')"
      :sub-title="$t('offers_page.subtitle_subtitle')"
    />
    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div
        v-if="getAllOffers && getAllOffers.length < 1"
        class="d-flex flex-wrap flex-1 px-0"
      >
        <b-col lg="12" md="12" class="px-0">
          <wameed-no-data
            icon="nodata-icon"
            :title="$t('offers_page.no_data')"
            :sub-title="$t('offers_page.no_data_text')"
          />
        </b-col>
      </div>
      <div
        v-for="(offer, i) in listOffers"
        v-if="getAllOffers && getAllOffers.length > 0"
        :key="i"
        class="d-flex flex-wrap flex-1 px-0"
      >
        <b-col md="12" class="px-3 py-2 d-flex justify-content-between">
          <h3 class="text-bold-18 text-font-main mb-0">
            {{ $t('offers_page.offers_made') }}
            <span class="mx-2 text-reg-18"> {{ offer[0].hr_job_title }}</span>
          </h3>
          <router-link
            :to="{
              name: 'offers-project',
              params: { lang: $i18n.locale, id: offer[0].projects_id },
            }"
            class="text-bold-18 text-font-secondary text_decoration"
          >
            <span :class="'mx-0 '">{{ $t('common.see_all_offers') }}</span>
          </router-link>
        </b-col>
        <b-col
          v-for="(item, index) in offer"
          :key="index"
          lg="3"
          md="3"
          class="py-3"
        >
          <offers-card
            :id="item.id"
            :title="item.name"
            :sub-title="item.created_at"
            :date="item.created_at"
            :employee-job-title="item.employee_job_title"
            :price="item.price"
          />
        </b-col>
      </div>
      <b-col
        v-if="getAllOffers && getAllOffers.length > 0"
        lg="12"
        md="12"
        class="px-0"
      >
        <wameed-pagination
          v-model="filterData.page"
          :page="filterData.page"
          :total-items="getTotalOffers.totalItems"
          :per_page="filterData.per_page"
          @changePage="changePage"
          @changeCurrentPage="changeCurrentPage"
        />
      </b-col>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import offersCard from '@/components/pages/orders/OffersCard.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // WTables,
    offersCard,
  },
  data() {
    return {
      filterData: {
        status: '2',
        projects: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: this.$i18n.t('common.rejected'), id: 0 },
            { title: this.$i18n.t('common.agreed'), id: 1 },
            { title: this.$i18n.t('common.under_review'), id: 2 },
            { title: this.$i18n.t('common.agreed2'), id: 3 },
          ],
        },
      ],
      status: [
        {
          0: 'rejected',
          1: 'agreed',
          2: 'under_review',
          3: 'agreed2',
        },
        {
          0: 'danger',
          1: 'warning',
          2: 'font-secondary',
          3: 'success',
        },
      ],
      listOffers: [],
    };
  },
  computed: {
    ...mapGetters({
      getAllOffers: 'getAllOffers',
      getTotalOffers: 'getTotalOffers',
    }),
  },
  watch: {
    getAllOffers(data) {
      const grouped = this._.groupBy(data, 'hr_job_title');
      this.listOffers = grouped;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    ...mapActions({
      loadOffers: 'loadOffers',
    }),
    loadData() {
      this.loadOffers(this.filterData);
    },
    offersDetails(id) {
      this.$router.push({
        name: 'orders-offers-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
  },
};
</script>
