<template>
  <b-card
    no-body
    class="cursor-pointer wameed-card h-100 m-0"
    :class="
      checkBoxSelection
        ? 'check-box-selection'
        : '' || offerSent
        ? 'check-offer-sent'
        : ''
    "
    @click="$emit('submitAction', id)"
  >
    <b-card-header class="flex-nowrap align-items-start pb-0">
      <b-media no-body class="flex-wrap w-100">
        <b-media-body>
          <div class="d-flex align-items-start justify-content-between w-100">
            <div>
              <h5 class="text-bold-18 text-font-main mb-1 mx-2">
                {{ title }}
              </h5>
              <h5 class="text-book-14 text-font-sub mb-3 mx-2">
                {{ formatedDate(subTitle) }}
              </h5>
            </div>

            <check-box-offer-icon
              style="width: 36px; height: 36px"
              v-if="checkBox"
              :class="
                checkBoxSelection
                  ? 'check-box-selection-icon'
                  : '' || offerSent
                  ? 'check-offer-sent-icon'
                  : ''
              "
            />
          </div>
          <hr />
          <b-row
            class="package-details-table__body"
            style="background-color: inherit"
          >
            <b-col cols="7" class="package-details-name px-0">
              <p class="mb-0">{{ $t('orders_page.price') }}:</p>
            </b-col>
            <b-col cols="5" class="package-details-value px-0">
              <p class="mb-0">{{ price }} ر.س</p>
            </b-col>
            <b-col cols="7" class="package-details-name px-0">
              <p class="mb-0">
                {{ $t('form_wizard_order.job_title_required') }}:
              </p>
            </b-col>
            <b-col cols="5" class="package-details-value px-0">
              <p class="mb-0">
                {{ employeeJobTitle }}
              </p>
            </b-col>
            <b-col
              v-if="status || status == 0"
              cols="7"
              class="package-details-name px-0"
            >
              <p class="mb-0">{{ $t('common.offer_statuse') }}:</p>
            </b-col>
            <b-col
              v-if="status || status == 0"
              cols="5"
              class="package-details-value px-0 d-flex"
            >
              <b-badge
                pill
                :variant="'light-' + statusList[1][status]"
                :class="'border-' + statusList[1][status]"
                class="text-reg-12 py-0 px-3"
                >{{ $t('common.' + statusList[0][status]) }}</b-badge
              >
            </b-col>
            <b-col sm="12" class="package-details-value px-0 mt-5">
              <wameed-btn
                classes="rounded-10 text-bold-15"
                block
                :title="$t('common.view_all')"
                type="button"
                :variant="'disable'"
                @submitAction="viewDetails(id)"
              />
            </b-col>
          </b-row>
        </b-media-body>
      </b-media>
    </b-card-header>
  </b-card>
</template>

<script>
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: { WameedBtn },
  props: {
    id: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    employeeJobTitle: {
      type: String,
      default: null,
    },
    price: {
      type: String,
      default: null,
    },
    checkBox: {
      type: Boolean,
      default: null,
    },
    offerSent: {
      type: Boolean,
      default: null,
    },
    checkBoxSelection: {
      type: Boolean,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      statusList: [
        {
          0: 'rejected',
          1: 'active',
          2: 'under_review',
          3: 'agreed',
        },
        {
          0: 'danger',
          1: 'info',
          2: 'font-secondary',
          3: 'success',
        },
      ],
    };
  },
  methods: {
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    cardAction(id) {
      this.$emit('cardAction', id);
    },
    viewDetails(id) {
      this.$router.push({
        name: 'offers-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
  },
};
</script>
