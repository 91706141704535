var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"cursor-pointer wameed-card h-100 m-0",class:_vm.checkBoxSelection
      ? 'check-box-selection'
      : '' || _vm.offerSent
      ? 'check-offer-sent'
      : '',attrs:{"no-body":""},on:{"click":function($event){return _vm.$emit('submitAction', _vm.id)}}},[_c('b-card-header',{staticClass:"flex-nowrap align-items-start pb-0"},[_c('b-media',{staticClass:"flex-wrap w-100",attrs:{"no-body":""}},[_c('b-media-body',[_c('div',{staticClass:"d-flex align-items-start justify-content-between w-100"},[_c('div',[_c('h5',{staticClass:"text-bold-18 text-font-main mb-1 mx-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h5',{staticClass:"text-book-14 text-font-sub mb-3 mx-2"},[_vm._v(" "+_vm._s(_vm.formatedDate(_vm.subTitle))+" ")])]),(_vm.checkBox)?_c('check-box-offer-icon',{class:_vm.checkBoxSelection
                ? 'check-box-selection-icon'
                : '' || _vm.offerSent
                ? 'check-offer-sent-icon'
                : '',staticStyle:{"width":"36px","height":"36px"}}):_vm._e()],1),_c('hr'),_c('b-row',{staticClass:"package-details-table__body",staticStyle:{"background-color":"inherit"}},[_c('b-col',{staticClass:"package-details-name px-0",attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('orders_page.price'))+":")])]),_c('b-col',{staticClass:"package-details-value px-0",attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.price)+" ر.س")])]),_c('b-col',{staticClass:"package-details-name px-0",attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('form_wizard_order.job_title_required'))+": ")])]),_c('b-col',{staticClass:"package-details-value px-0",attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.employeeJobTitle)+" ")])]),(_vm.status || _vm.status == 0)?_c('b-col',{staticClass:"package-details-name px-0",attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('common.offer_statuse'))+":")])]):_vm._e(),(_vm.status || _vm.status == 0)?_c('b-col',{staticClass:"package-details-value px-0 d-flex",attrs:{"cols":"5"}},[_c('b-badge',{staticClass:"text-reg-12 py-0 px-3",class:'border-' + _vm.statusList[1][_vm.status],attrs:{"pill":"","variant":'light-' + _vm.statusList[1][_vm.status]}},[_vm._v(_vm._s(_vm.$t('common.' + _vm.statusList[0][_vm.status])))])],1):_vm._e(),_c('b-col',{staticClass:"package-details-value px-0 mt-5",attrs:{"sm":"12"}},[_c('wameed-btn',{attrs:{"classes":"rounded-10 text-bold-15","block":"","title":_vm.$t('common.view_all'),"type":"button","variant":'disable'},on:{"submitAction":function($event){return _vm.viewDetails(_vm.id)}}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }